import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "desktop-maker"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/rank/maker-ranking.png"}) { ...eyecatchImg },
    delldesktop: file(relativePath: { eq: "pcmaker/dell-desktop.png"}) { ...normalImg },
    koubou: file(relativePath: { eq: "pcmaker/koubou.png"}) { ...normalImg },
    frontier: file(relativePath: { eq: "pcmaker/frontier.png"}) { ...normalImg },
    seven: file(relativePath: { eq: "pcmaker/seven.png"}) { ...normalImg },
    storm: file(relativePath: { eq: "pcmaker/storm.png"}) { ...normalImg },
    mouse: file(relativePath: { eq: "pcmaker/mouse.png"}) { ...normalImg },
    lenovodesktop: file(relativePath: { eq: "pcmaker/lenovo-desktop.png"}) { ...normalImg },
    hpdesktop: file(relativePath: { eq: "pcmaker/hp-desktop.png"}) { ...normalImg },
    tsukumo: file(relativePath: { eq: "pcmaker/tsukumo.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`【コスパ最強】デスクトップパソコンのおすすめメーカーランキング`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="デスクトップパソコンのおすすめメーカーランキング" mdxType="Image" />
    <p>{`おすすめのメーカーはと聞かれても、質問者のパソコンに対する現在の知識や重視するポイントが何か分からなければ答えることは難しい。`}</p>
    <p>{`しかし賛否はあれどデスクトップパソコンにおいて敢えて言うならば、コストパフォーマンスが高いこと、すなわち`}<strong parentName="p"><em parentName="strong">{`価格の割にハイスペックな部品を搭載できていることが最重要である`}</em></strong>{`と言い切れる。`}</p>
    <p>{`今回は私がコスパを最重視する理由を説明した上で、`}<strong parentName="p"><em parentName="strong">{`コスパが高いパソコンメーカーをランキング形式で紹介`}</em></strong>{`する。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "なぜデスクトップメーカー選びにコストパフォーマンスが重要か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%AA%E3%81%9C%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%83%A1%E3%83%BC%E3%82%AB%E3%83%BC%E9%81%B8%E3%81%B3%E3%81%AB%E3%82%B3%E3%82%B9%E3%83%88%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%81%8C%E9%87%8D%E8%A6%81%E3%81%8B%EF%BC%9F",
        "aria-label": "なぜデスクトップメーカー選びにコストパフォーマンスが重要か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`なぜデスクトップメーカー選びにコストパフォーマンスが重要か？`}</h2>
    <p>{`パソコンはCPU、メモリ、ストレージといった汎用品を組み合わせて完成するものであり、どのパソコンメーカーも`}<strong parentName="p"><em parentName="strong">{`本質的には組み立てを行っているだけ`}</em></strong>{`である。ノートパソコンであれば排熱構造や軽量化、タッチパネルなど各社特色を出しやすいものの、`}<strong parentName="p"><em parentName="strong">{`デスクトップパソコンに関しては差異化できる点がほとんどない`}</em></strong>{`。`}</p>
    <p>{`OSも各社Windowsを搭載しているため、機能面での差はほとんどなく、差異化のポイントとしては`}<strong parentName="p"><em parentName="strong">{`コスト、デザイン、サポート体制、初期ソフト`}</em></strong>{`ぐらいとなる。`}</p>
    <p>{`このうちデザインは持ち歩くノートパソコンに比べれば優先順位は低い。`}</p>
    <p>{`サポート体制に関して、操作方法のサポートに関しては不要である。今は検索すれば大抵答えは見つかる上に、デスクトップパソコンは蓋を空けて自分で問題解決ができるケースが多い。(ただ製品保証など最低限のサポートに不安があるメーカーに関しては除くが)`}</p>
    <p>{`初期ソフトも不要である。最初から付属するとごちゃごちゃして必要なソフトが探しにくくなるうえ、不要なソフトのために余計な初期コストを支払う理由はどこにも存在しない。`}</p>
    <p>{`よって残るはコストのみということになる。`}<strong parentName="p"><em parentName="strong">{`パソコン作業の快適さの大半がスペックで決まってくるため、より高性能な部品を如何に格安で入手できるかが重要なポイント`}</em></strong>{`である。`}</p>
    <AccentBox title="デスクトップパソコン選びのポイント" mdxType="AccentBox">
  <li><span className="bold">コスト</span>・・・価格を抑えて高性能なパソコンを入手するために意識すべきポイント</li>
  <li><span className="bold">デザイン</span>・・・要素の一つだがまずは価格を見るべきか</li>
  <li><span className="bold">サポート体制</span>・・・スマホでググれば大体解決、修理の時も購入メーカーを頼る必要は無し</li>
  <li><span className="bold">初期ソフト</span>・・・初期ソフトでパソコンが重く、画面が煩わしくなる可能性も。探せばすぐに代替ソフトは見つかる。</li>
    </AccentBox>
    <p>{`CPUやグラフィックボードの情報は誤魔化せないが、`}<strong parentName="p">{`SSDや電源の種類などの人目に付きにくい所で格安メーカーは細部を削っており`}</strong>{`、それが安さに繋がっており真のコスパが見抜きくいところもある。この辺りも考慮しランキングを作成した。`}</p>
    <h2 {...{
      "id": "デスクトップを敢えて求める意義について再確認",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%82%92%E6%95%A2%E3%81%88%E3%81%A6%E6%B1%82%E3%82%81%E3%82%8B%E6%84%8F%E7%BE%A9%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6%E5%86%8D%E7%A2%BA%E8%AA%8D",
        "aria-label": "デスクトップを敢えて求める意義について再確認 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デスクトップを敢えて求める意義について再確認`}</h2>
    <p>{`家電量販店でパソコンを探しても、デスクトップパソコンはゲーミング用途を除けばほとんど売られていない。`}</p>
    <p>{`しかしノートパソコンと比べ`}<strong parentName="p"><em parentName="strong">{`コストパフォーマンスが圧倒的に高く`}</em></strong>{`、電力を十分に供給できることで`}<strong parentName="p"><em parentName="strong">{`安定性に優れ`}</em></strong>{`、大型のモニタを搭載でき、キーボードとモニタは分離していることから無理のない姿勢が取りやすいなど`}<strong parentName="p"><em parentName="strong">{`快適性が高い`}</em></strong>{`。`}</p>
    <p>{`要するに`}<strong parentName="p"><em parentName="strong">{`常に定位置で使うならばデスクトップの方が確実に高い生産性を実現できる`}</em></strong>{`のである。さらに壊れてもパーツ単位で買い替えれば良いため修理費は圧倒的に安い。`}</p>
    <p>{`10万円もあればモニター付きで安定して高速という環境が手に入り、20万円あればグラボ付きで最先端のゲーム体験も可能となる。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`最強の生産性と快適性を求めるならばデスクトップパソコンは間違いなく買い`}</em></strong>{`である。ちなみに私も作業はほとんどデスクトップで行なっている。`}</p>
    <h2 {...{
      "id": "おすすめメーカーランキング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%83%A1%E3%83%BC%E3%82%AB%E3%83%BC%E3%83%A9%E3%83%B3%E3%82%AD%E3%83%B3%E3%82%B0",
        "aria-label": "おすすめメーカーランキング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`おすすめメーカーランキング`}</h2>
    <p>{`それではデスクトップパソコンのおすすめのメーカーを紹介していく、上で述べた通りデスクトップパソコンを選ぶならばサービスやソフト等はどうであれ、コストパフォーマンスを重視して紹介していく。`}</p>
    <p>{`日系大手のNECや富士通のような高価格帯のメーカーの紹介は除き、`}<strong parentName="p"><em parentName="strong">{`コスパを考慮したときに購入に値するメーカーのみを厳選して紹介`}</em></strong>{`する。`}</p>
    <h3 {...{
      "id": "１位：-デル---コスパの黒船、大企業の安定感",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%91%E4%BD%8D%EF%BC%9A-%E3%83%87%E3%83%AB---%E3%82%B3%E3%82%B9%E3%83%91%E3%81%AE%E9%BB%92%E8%88%B9%E3%80%81%E5%A4%A7%E4%BC%81%E6%A5%AD%E3%81%AE%E5%AE%89%E5%AE%9A%E6%84%9F",
        "aria-label": "１位： デル   コスパの黒船、大企業の安定感 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`１位： デル - コスパの黒船、大企業の安定感`}</h3>
    <Image {...props} name="delldesktop" alt="デル" mdxType="Image" />
    <p>{`アメリカ合衆国のテキサス州に本社を置く大手メーカーで時価総額は４兆円程度と、日本のパナソニックを凌ぐ企業価値である。パソコンは世界シェアトップクラスであり、パソコン以外にもクラウドやセキュリティなどのITソリューションサービスを手がけており業務は幅広い。`}</p>
    <p>{`パソコン販売では店舗販売をほとんど行わず、ネット通販に特に注力しているためコスト競争力は高く、特に`}<strong parentName="p"><em parentName="strong">{`InspironとVostroはその中でも価格が抑えられている`}</em></strong>{`。スモールシャーシーとミニタワー型のデスクトップパソコンを検討する時には必ず候補としたい。`}</p>
    <p>{`ただパソコンは海外から発送のものが多く、即納モデル以外は納期まで10日程度掛かるのが欠点。楽しみながらじっくり待とう。`}</p>
    <AccentBox title="サブブランド" mdxType="AccentBox">
  <li><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10011380&type=3&subid=0" rel="nofollow noopener" target="_blank">Inspiron</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10011380&type=3&subid=0" />: 圧倒的な価格競争力でWiFi/Bluetooth機能まで搭載。必要十分であり管理人も家族用に購入。</li>
  <li><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10010368&type=3&subid=0" rel="nofollow noopener" target="_blank">Vostro</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10010368&type=3&subid=0" />: スリム型デスクトップパソコンを望むなら検討したい機種。ビジネス用に開発されているので無駄がなく、個人でも購入可能。</li>
    </AccentBox>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10006795&type=3&subid=0" target="_blank" rel="nofollow noopener">Dell【個人向け】デスクトップ一覧</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10006795&type=3&subid=0" />
    </SmallBox>
    <h3 {...{
      "id": "２位：-Lenovo---デルと双璧、世界シェアNo1でコスパ最強の一角",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%92%E4%BD%8D%EF%BC%9A-Lenovo---%E3%83%87%E3%83%AB%E3%81%A8%E5%8F%8C%E7%92%A7%E3%80%81%E4%B8%96%E7%95%8C%E3%82%B7%E3%82%A7%E3%82%A2No1%E3%81%A7%E3%82%B3%E3%82%B9%E3%83%91%E6%9C%80%E5%BC%B7%E3%81%AE%E4%B8%80%E8%A7%92",
        "aria-label": "２位： Lenovo   デルと双璧、世界シェアNo1でコスパ最強の一角 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`２位： Lenovo - デルと双璧、世界シェアNo1でコスパ最強の一角`}</h3>
    <Image {...props} name="lenovodesktop" alt="LENOVO" mdxType="Image" />
    <p>{`IBMのパソコン事業を買収して引き継いだ中国の巨大メーカー。北京に本社を構える。世界シェアトップでNECや富士通にも部材を供与している。`}</p>
    <p>{`高いシェアから来るパーツの大量購入により`}<strong parentName="p"><em parentName="strong">{`原価率を抑えているためコストパフォーマンスは当然高く`}</em></strong>{`、また特に高コスパの量販モデルは大量生産により`}<strong parentName="p"><em parentName="strong">{`品質も安定`}</em></strong>{`している。`}</p>
    <p>{`製品別で行くと、IdeaCentreはゲームをしない人でノートでは物足りないパワーを求めたい人には最適な選択肢である。Wifi6にも対応して配線に困らない。`}</p>
    <AccentBox title="サブブランド" mdxType="AccentBox">
  <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887538317&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fdesktops%2Fresults%2F%3FvisibleDatas%3D2115%253AIdeaCentre%26cid%3Djp%3Aaffiliate%3Adsry7f" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887538317" height="1" width="0" border="0" alt="" />IdeaCentre</a>: 家庭用に適したデスクトップ、WiFi/Bluetoothも対応でオールインワンモデルも低価格。</li>
  <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887538317&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fdesktops%2Fresults%2F%3FvisibleDatas%3D2115%253ALegion%26cid%3Djp%3Aaffiliate%3Adsry7f" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887538317" height="1" width="0" border="0" alt="" />LEGION</a>: スマートでかつアグレッシブなデザインのゲーミングPC</li>
    </AccentBox>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886331569&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fdesktops%2Fc%2Fdesktops%3Fcid%3Djp%3Aaffiliate%3Adsry7f" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886331569" height="1" width="0" border="0" alt="" />LENOVO(デスクトップ一覧)</a>
    </SmallBox>
    <h3 {...{
      "id": "３位：-パソコン工房---コスパで選ぶならBTOで最初に見るべき",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%93%E4%BD%8D%EF%BC%9A-%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E5%B7%A5%E6%88%BF---%E3%82%B3%E3%82%B9%E3%83%91%E3%81%A7%E9%81%B8%E3%81%B6%E3%81%AA%E3%82%89BTO%E3%81%A7%E6%9C%80%E5%88%9D%E3%81%AB%E8%A6%8B%E3%82%8B%E3%81%B9%E3%81%8D",
        "aria-label": "３位： パソコン工房   コスパで選ぶならBTOで最初に見るべき permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`３位： パソコン工房 - コスパで選ぶならBTOで最初に見るべき`}</h3>
    <Image {...props} name="koubou" alt="パソコン工房" mdxType="Image" />
    <p>{`信頼性が高いBTOの老舗メーカー。パソコンの中上級者が好んで購入するが購入の敷居は高くない。`}</p>
    <p>{`マウスコンピュータの子会社であるものの、初中級者に人気の高いマウスコンピュータと比べるとサポートコストや広告宣伝費を抑えられるのかコストパフォーマンスが高い。`}</p>
    <p>{`通常のデスクトップパソコンからゲーミングパソコンまで幅広く展開し、`}<strong parentName="p"><em parentName="strong">{`ローエンドからハイエンドまで全てのパソコンで価格が安定して安い`}</em></strong>{`。`}</p>
    <p>{`比較検討をする時の一つのベースとなってくるので必ずチェックしよう。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000209&type=3&subid=0" target="_blank" rel="nofollow noopener">パソコン工房</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000209&type=3&subid=0" />
    </SmallBox>
    <h3 {...{
      "id": "４位：-SEVEN---希望のスペックを高コスパで",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%94%E4%BD%8D%EF%BC%9A-SEVEN---%E5%B8%8C%E6%9C%9B%E3%81%AE%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%82%92%E9%AB%98%E3%82%B3%E3%82%B9%E3%83%91%E3%81%A7",
        "aria-label": "４位： SEVEN   希望のスペックを高コスパで permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`４位： SEVEN - 希望のスペックを高コスパで`}</h3>
    <Image {...props} name="seven" alt="SEVEN" mdxType="Image" />
    <p>{`株式会社セブンアールジャパンという小規模な会社が製造・販売をしているパソコン。製造から販売まで秋葉原で行い、上級者向けのコアなパソコンも販売。`}</p>
    <p>{`もともとある程度パソコンに詳しい人しか購入しないため、サポート等はそれほど期待できないが、`}<strong parentName="p"><em parentName="strong">{`ローエンドのものからプロ顔負けの動画編集に使うような超ハイスペックなカスタマイズパソコンまで高いコスパで手に入れることができる`}</em></strong>{`。`}</p>
    <p>
スペックからパソコンを絞り込め、価格順で並び変える事ができるので、<strong><b>すぐに希望のパソコンを探し比較検討を行うことができる</b></strong>。当サイトの<Link to="/cpu/" mdxType="Link">CPU性能比較表</Link>などを参考に絞り込みを行ないたい。
    </p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886645278" target="_blank" rel="nofollow noopener">BTOパソコン通販 パソコンショップSEVEN</a><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886645278" height="1" width="1" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "５位：-ストーム---高品質な部品へのこだわり",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%95%E4%BD%8D%EF%BC%9A-%E3%82%B9%E3%83%88%E3%83%BC%E3%83%A0---%E9%AB%98%E5%93%81%E8%B3%AA%E3%81%AA%E9%83%A8%E5%93%81%E3%81%B8%E3%81%AE%E3%81%93%E3%81%A0%E3%82%8F%E3%82%8A",
        "aria-label": "５位： ストーム   高品質な部品へのこだわり permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`５位： ストーム - 高品質な部品へのこだわり`}</h3>
    <Image {...props} name="storm" alt="STORM" mdxType="Image" />
    <p>{`一般販売の他、BtoBにも力を入れる老舗メーカー。価格帯をざっとみたところ一見コスパが悪そうに見えるが、全くそんなことはなく、`}<strong parentName="p"><em parentName="strong">{`熱効率の良い電源、メモリなど細部に渡り高品質なパーツで統一`}</em></strong>{`している。`}</p>
    <p>{`特に、`}<strong parentName="p"><em parentName="strong">{`価格が10万円以上するミドルレンジ以上のパソコンになってくると割安感が増してくる。`}</em></strong>{`税込み価格での表記もBTOメーカーでは珍しく好感が持てる。`}</p>
    <p>{`BtoBの面では、マイクロソフトやNTTなど幅広い大手企業にパソコンを納入していることも安心感がある。`}</p>
    <p>{`また、アルミ仕様のケースや、キューブ型パソコンなど、オリジナル感のあるパソコンを探している場合は是非チェックしておきたいメーカーである。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886331628" rel="nofollow noopener" target="_blank">STORM</a><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886331628" height="1" width="1" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "６位：-フロンティア---ゲーミングPCのセール品は一見の価値あり",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%96%E4%BD%8D%EF%BC%9A-%E3%83%95%E3%83%AD%E3%83%B3%E3%83%86%E3%82%A3%E3%82%A2---%E3%82%B2%E3%83%BC%E3%83%9F%E3%83%B3%E3%82%B0PC%E3%81%AE%E3%82%BB%E3%83%BC%E3%83%AB%E5%93%81%E3%81%AF%E4%B8%80%E8%A6%8B%E3%81%AE%E4%BE%A1%E5%80%A4%E3%81%82%E3%82%8A",
        "aria-label": "６位： フロンティア   ゲーミングPCのセール品は一見の価値あり permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`６位： フロンティア - ゲーミングPCのセール品は一見の価値あり`}</h3>
    <Image {...props} name="frontier" alt="フロンティア" mdxType="Image" />
    <p>{`BTOの草分け的なブランド。もともとはフロンティア神代という会社だったが、経営の悪化からヤマダ電気の完全子会社となり、同様に子会社のインバースが事業を引き継いでいる。`}</p>
    <p>{`製品はオンラインが中心であるが、秋葉原のインバースショップやヤマダ電機の一部の店舗でも購入することができる。`}</p>
    <p>{`格安デスクトップPCを売りとしており、教育機関や研究施設などへの導入実績も厚い。必要な機能に絞ったパソコンを開発していると言える。`}</p>
    <p>{`特に`}<strong parentName="p"><em parentName="strong">{`グラフィックボード搭載のセール品は高コスパ`}</em></strong>{`なので、ゲーミングパソコンを検討の場合は必ずチェックをしておきたい。一方でグラボを搭載していないモデルはあまり期待はできない。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886332052" rel="nofollow noopener" target="_blank">FRONTIERダイレクト</a><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886332052" alt="" height="1" width="1" /> / 
  <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886332066&vc_url=https%3A%2F%2Fwww.frontier-direct.jp%2Fdirect%2Fc%2Fcsaleinfo%2F" rel="nofollow noopener" target="_blank">セール・特集</a><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886332066" alt="" height="1" width="1" />
    </SmallBox>
    <h3 {...{
      "id": "７位：マウスコンピュータ---豊富なラインナップは要チェック",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%97%E4%BD%8D%EF%BC%9A%E3%83%9E%E3%82%A6%E3%82%B9%E3%82%B3%E3%83%B3%E3%83%94%E3%83%A5%E3%83%BC%E3%82%BF---%E8%B1%8A%E5%AF%8C%E3%81%AA%E3%83%A9%E3%82%A4%E3%83%B3%E3%83%8A%E3%83%83%E3%83%97%E3%81%AF%E8%A6%81%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF",
        "aria-label": "７位：マウスコンピュータ   豊富なラインナップは要チェック permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`７位：マウスコンピュータ - 豊富なラインナップは要チェック`}</h3>
    <Image {...props} name="mouse" alt="マウスコンピュータ" mdxType="Image" />
    <p>{`乃木坂のCMにより有名となった株式会社マウスコンピュータが運営するパソコンストア。東京都中央区に本社があり、液晶モニターメーカーであるイーヤマを吸収合弁しておりiiyamaブランドのモニター販売も手がける。`}</p>
    <p>{`パソコンではゲーミングPCブランドのGTune、クリエイティブPCブランドのDAIVなど細かくモデルがある。`}</p>
    <p>{`マウスコンピュータは用途に応じて幅広くラインナップを提供しているため`}<strong parentName="p"><em parentName="strong">{`パソコンの構成に無駄が無い`}</em></strong>{`。さらにパソコンに詳しくない人もターゲットとしているため、信頼性や製品に関するサポート体制も厚い。`}</p>
    <p>{`コスパも悪くないため、`}<strong parentName="p"><em parentName="strong">{`はじめてネットでパソコンを購入するというケースでは非常におすすめできる`}</em></strong>{`メーカーである。`}</p>
    <AccentBox title="サブブランド" mdxType="AccentBox">
  <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886847771" rel="nofollow noopener" target="_blank">G-Tune</a><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886847771" height="1" width="1" alt="" />: マウスのゲーミングブランド。コスパもそれなり。</li>
  <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886847773" rel="nofollow noopener" target="_blank">DAIV</a><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886847773" height="1" width="1" alt="" />: クリエイター向けのブランド。Adobe After Effectsを使用する人や3Dモデリングを行う人などプロのクリエイティブ作業に最適なモデル。</li>
    </AccentBox>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886847755" rel="nofollow noopener" target="_blank">マウスコンピュータ</a><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886847755" height="1" width="1" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "８位：-HP---デザインとコストを高い次元で両立",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%98%E4%BD%8D%EF%BC%9A-HP---%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%81%A8%E3%82%B3%E3%82%B9%E3%83%88%E3%82%92%E9%AB%98%E3%81%84%E6%AC%A1%E5%85%83%E3%81%A7%E4%B8%A1%E7%AB%8B",
        "aria-label": "８位： HP   デザインとコストを高い次元で両立 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`８位： HP - デザインとコストを高い次元で両立`}</h3>
    <Image {...props} name="hpdesktop" alt="HP" mdxType="Image" />
    <p>{`HP(ヒューレッドパッカード)はPCやプリンタなどコンピュータ関連製品の製造販売を行うシリコンバレーの大企業。世界シェアは常に１位・２位をLENOVOと争う。`}</p>
    <p>{`デザインはデスクトップパソコンではそれほど重視したい項目ではないものの、コストパフォーマンスも悪くないため、`}<strong parentName="p"><em parentName="strong">{`インテリアとの調和を優先するならば有力な選択肢となる`}</em></strong>{`だろう。`}</p>
    <AccentBox title="サブブランド" mdxType="AccentBox">
  <li><a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=35909&murl=https%3A%2F%2Fjp.ext.hp.com%2Fdesktops%2Fpersonal%2F" rel="nofollow noopener" target="_blank">Pavilion</a>
    <img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.1&type=10" />: HP人気No1の個人向けデスクトップPC。スモールシャーシならDellのInspironかPavilionは悩みどころ。</li>
  <li><a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=35909&murl=https%3A%2F%2Fjp.ext.hp.com%2Fgaming%2Fpersonal%2F" rel="nofollow noopener" target="_blank">OMEN</a>
    <img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.1&type=10" />: プロゲーマーの期待に答え続けるこだわりのゲーミングPC、コスパはやや悪め。価格競争力ではPavilion Gamingがおすすめ。</li>
    </AccentBox>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=252926.28&type=3&subid=0" target="_blank" rel="nofollow noopener">HP</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.28&type=3&subid=0" />
    </SmallBox>
    <h3 {...{
      "id": "９位：-TSUKUMO---最先端のパーツをいち早く低価格で",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%99%E4%BD%8D%EF%BC%9A-TSUKUMO---%E6%9C%80%E5%85%88%E7%AB%AF%E3%81%AE%E3%83%91%E3%83%BC%E3%83%84%E3%82%92%E3%81%84%E3%81%A1%E6%97%A9%E3%81%8F%E4%BD%8E%E4%BE%A1%E6%A0%BC%E3%81%A7",
        "aria-label": "９位： TSUKUMO   最先端のパーツをいち早く低価格で permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`９位： TSUKUMO - 最先端のパーツをいち早く低価格で`}</h3>
    <Image {...props} name="tsukumo" alt="TSUKUMO" mdxType="Image" />
    <p>{`2009年にヤマダ電機に買収されたパソコンメーカーで、秋葉原にて企画、設計を行っている。最新パーツをいち早くパソコンに搭載し、価格も低価格。パソコンパーツのショップとしても有名である。`}</p>
    <p>{`コスパがとりわけ高いわけではないが、`}<strong parentName="p"><em parentName="strong">{`キャラクターコラボ等購入意欲をそそられるモデルも多く`}</em></strong>{`気になる存在である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886483488" target="_blank" rel="nofollow noopener">TSUKUMO</a><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886483488" height="1" width="1" alt="" />
    </SmallBox>
    <h2 {...{
      "id": "パソコンメーカーの選び方",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%83%A1%E3%83%BC%E3%82%AB%E3%83%BC%E3%81%AE%E9%81%B8%E3%81%B3%E6%96%B9",
        "aria-label": "パソコンメーカーの選び方 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコンメーカーの選び方`}</h2>
    <p>{`NECや富士通といった初心者向けプレミアム路線のPCを除去して、最安値が見つかるパソコンメーカーを上記では絞ってきたが、それでも一択では無く、多くのメーカーから再び絞らなければならない。`}</p>
    <p>{`私自身は購入のたびにメーカーを変更しており、マウスだったりデルであったりと行ったり来たりしている。しかし選び方は一貫したものがあるので紹介したい。`}</p>
    <h3 {...{
      "id": "拡張不要のボリュームゾーンモデルはDellHPLenovoから選ぶ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%8B%A1%E5%BC%B5%E4%B8%8D%E8%A6%81%E3%81%AE%E3%83%9C%E3%83%AA%E3%83%A5%E3%83%BC%E3%83%A0%E3%82%BE%E3%83%BC%E3%83%B3%E3%83%A2%E3%83%87%E3%83%AB%E3%81%AFDellHPLenovo%E3%81%8B%E3%82%89%E9%81%B8%E3%81%B6",
        "aria-label": "拡張不要のボリュームゾーンモデルはDellHPLenovoから選ぶ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`拡張不要のボリュームゾーンモデルはDell/HP/Lenovoから選ぶ`}</h3>
    <p>{`パソコン購入時のスペックでそのまま使いかつ、そのスペックがボリュームゾーンのものである場合には、大手外資系が最もコストパフォーマンスが高い場合が多い。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`DellのInspironとLENOVOのIdeaCentreは特に有望な選択肢`}</em></strong>{`となるだろう。`}</p>
    <p>{`これらの機種は`}<strong parentName="p"><em parentName="strong">{`消費電力が低く電気代でもメリット`}</em></strong>{`が得られ、さらに無線機能が標準的に搭載されており、スペックもその機種に最適にチューニングされたものとなっている。`}</p>
    <p>{`また、モニターと一体となったオールインワンパソコンが欲しい場合はこれらのメーカーしか販売していないこともありDell/HP/Lenovo等から選ぶこととなる。`}</p>
    <SmallBox type="word accent" text="消費電力の試算" mdxType="SmallBox">
平常時の消費電力が大手外資系メーカーのものがBTOメーカーと比べると30W程度は少ないと思われる。1日4時間パソコンの作業をするとして5年使うとすると年間で約1500円、<b>５年で7500円程度電気代がお得</b>になる。BTOと迷った場合には、この価格差もある程度考慮に入れると良いかもしれない。
    </SmallBox>
    <h3 {...{
      "id": "今後拡張予定の場合はBTOメーカー",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BB%8A%E5%BE%8C%E6%8B%A1%E5%BC%B5%E4%BA%88%E5%AE%9A%E3%81%AE%E5%A0%B4%E5%90%88%E3%81%AFBTO%E3%83%A1%E3%83%BC%E3%82%AB%E3%83%BC",
        "aria-label": "今後拡張予定の場合はBTOメーカー permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`今後拡張予定の場合はBTOメーカー`}</h3>
    <p><strong parentName="p"><em parentName="strong">{`メモリの増設以外の拡張を自分で行ないたいと考えている場合はBTOパソコン`}</em></strong>{`を購入しておいた方が良いだろう。すなわちパソコンメーカーランキングで紹介したパソコン工房、SEVENなどのDell/HP/Lenovo以外のメーカーから選ぶと良いと思われる。`}</p>
    <p>{`BTOパソコンは拡張を行なうことを前提として構成が組まれていることが多いため、ケースのサイズや電源構成など余裕がある場合がほとんどのためである。このため`}<strong parentName="p"><em parentName="strong">{`グラフィックボードの拡張、HDDの追加といったことが容易にできる`}</em></strong>{`。`}</p>
    <p>{`大手メーカー製のPCでも拡張は可能だが、電源を十分に確保できていないなど柔軟性に乏しいケースがあるため、事前のスペック把握には十分気を付ける必要がある。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      